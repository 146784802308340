<template>
	<el-row class="row-div" >
	    <div class="login-box">
			<div class="left-title">
				<p class="title-p">{{leftTitle}}</p>
				<p class="title-p">{{projectTitle}}</p>
				<img src="/img/left-logo.png" width="368" height="255" style="margin-top: 80px;">
			</div>
			<div class="right-form">
				<p class="title-p" style="color: #0052D9;font-size: 24px;text-align: center;margin-bottom: 50px;">欢迎登陆</p>
				<el-input v-model="uname" placeholder="请输入账号" size="large" />
				<el-input v-model="password" show-password placeholder="请输入密码" size="large" style="margin-top: 30px;"/>
				<el-row style="justify-content: center;margin-top: 50px;">
					<el-button color="#2968D4" @click="submitForm" @click.native.prevent="submitForm">登 录</el-button>
				</el-row>
				
			</div>
		</div>
	</el-row>
</template>

<script>
	import md5 from 'js-md5';
	import { ElMessage } from 'element-plus'
	export default {
	  name: 'login',
	  components: {
	    
	  },
	  data(){
	    return{
			leftTitle:'济宁市育才中学',
			projectTitle:'公众号平台',
			uname:'',
			password:'',
			
	    }
	  },
	  methods:{
		  submitForm(){
			  if (!this.uname || !this.password) {
			  	ElMessage({
			  	    message: '密码或账号不可为空',
			  	    type: 'warning',
			  	  })
				  return
			  }
			  vaildInterface("wechat/user.php","login",{
			    keyword:'login',
			    username:this.uname,
			    password:md5(this.password)
			  },"01").then((data)=>{
			    //数据返回成功
			    if(data.success){
			      sessionStorage.setItem('ucode',JSON.stringify(data.data.user_info));
            sessionStorage.setItem('app_guid',data.data.user_info.app_guid);
            window.removeEventListener('keydown', this.keyDown, false)
				  this.menuRouter('');
			    }else{
					ElMessage.error(data.msg)
				}
			  }).catch((result=>{
			    //数据返回失败
			  }));
		  },
	    menuRouter(index){
	      this.$router.push('/home'+index);
	    },
      // 监听回车键执行事件
      keyDown (e) {
        // 回车则执行登录方法 enter键的ASCII是13
        if (e.keyCode  === 13) {
          this.submitForm() // 需要执行的方法方法
        }
      }
    },
    // 绑定监听事件
    mounted () {
      window.addEventListener('keydown', this.keyDown)
    },
	}
</script>

<style scoped>
	.row-div{
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		background: url('/img/bg.png') no-repeat;
		background-size: 100% 100%;
	}
	.login-box{
		height: 620px;
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		box-sizing: border-box;
		align-items: center;
		justify-content: center;
	}
	.left-title{
		background-color: #3481FF;
		border-radius: 30px;
		height: 100%;
		width: 490px;
		padding: 90px 60px;
		box-sizing: border-box;
	}
	.right-form{
		background-color: #fff;
		border-top-right-radius:30px;
		border-bottom-right-radius:30px;
		height: 430px;
		width: 530px;
		padding: 70px 80px;
		box-sizing: border-box;
	}
	.title-p{
		font-weight: bold;
		color: #FFFFFF;
		font-size: 34px;
		letter-spacing: 3px;
		line-height: 50px;
	}
</style>